<template>
	<shutter-panel ref="shutterPanel" />
</template>

<script type="text/javascript">
    import Shutter from "@/mixins/Shutter.js"
    import ShutterTiers from '@/mixins/shutters-manager/TiersCourrier.js'

	export default {
		name: 'TiersCourrier',
		mixins: [Shutter, ShutterTiers],
		props: ['tiers_id'],
		data () {
			return {
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.setupCourrierType(this.tiers_id)
			},
		}
	}

</script>